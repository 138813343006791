import Typography from "@mui/material/Typography";
import { theme } from "../../theme/theme";

const Footer = () => {
  return (
    <footer
      style={{
        textAlign: "center",
        lineHeight: "2px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: 15,
          backgroundColor: theme.palette.primary.main,
        }}
      ></div>
      <Typography variant="caption">
        TTC Group is dedicated to fleet road safety, providing an end-to-end
        driver risk management service committed to minimising fleet driver risk
        and optimising driver-related business performance
        <br />
        <a
          href="https://www.thettcgroup.com/privacy-policy/"
          style={{ color: "#5ea991", textDecoration: "none" }}
        >
          Privacy Policy
        </a>
        <br />© TTC Group 2022
      </Typography>
    </footer>
  );
};

export default Footer;
