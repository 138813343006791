import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#31296C",
      contrastText: "white",
    },
    secondary: {
      main: "#f50057",
    },
    success: {
      main: "#6bac00",
      light: "#B0DACE",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#999",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: "150px",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          "&:before": {
            border: "1px solid #E6E8ED",
          },
          color: "white",
        },
        tooltip: {
          backgroundColor: "white",
          border: "1px solid #E6E8ED",
          color: "#4A4A4A",
          fontSize: "1em",
        },
      },
    },
  },
  typography: {
    h1: { fontFamily: "Lato, sans-serif" },
    h2: { fontFamily: "Lato, sans-serif" },
    h3: { fontFamily: "Lato, sans-serif" },
    h4: { fontFamily: "Lato, sans-serif" },
    h5: { fontFamily: "Lato, sans-serif" },
    h6: { fontFamily: "Lato, sans-serif" },
    subtitle1: { fontFamily: "Lato, sans-serif" },
    subtitle2: { fontFamily: "Lato, sans-serif" },
    body1: { fontFamily: "Noto Sans, sans-serif" },
    body2: { fontFamily: "Noto Sans, sans-serif" },
    button: { fontFamily: "Noto Sans, sans-serif" },
    caption: { fontFamily: "Noto Sans, sans-serif" },
    overline: { fontFamily: "Noto Sans, sans-serif" },
  },
};

const theme = createTheme(themeOptions);

export { theme };
