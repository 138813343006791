import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { ToastProps } from "./IToastProps";

const SimpleToast = ({ alertType, open, text, closeCallback }: ToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={closeCallback}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      style={{ width: "auto" }}
    >
      <Alert
        onClose={closeCallback}
        severity={alertType || "success"}
        sx={{ width: "100%" }}
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SimpleToast;
