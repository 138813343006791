import { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { API_HELPER } from "../../routing/api";
import ConfirmationToast from "../toast/confirmation-toast";
import ProgressToast from "../toast/progress-toast";
import {
  BlobInfo,
  EditorContentStyleOptions,
  EditorPlugins,
  EditorToolbarOptions,
  FontFamilyOptions,
  ProgressFn,
  UploadHandler,
} from "../../misc/editor-options";

interface AppProps {
  articleId: string | undefined;
  initialValue?: string;
  height: number;
}

const getVideoElement = (source: string) => {
  return `
  <video controls="controls" width="300" height="150">
    <source src="${source}" />
  </video>
  `;
};

const TinyMCEEditor = ({ articleId, initialValue, height }: AppProps) => {
  const [editorContent, setEditorContent] = useState<string | undefined>(
    initialValue
  );
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);

  const editor = useRef(null);

  useEffect(() => {
    setEditorContent(initialValue);
  }, [initialValue]);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setUploaded(false);
  };

  const onUndoClick = () => {
    // undo the upload
  };

  const handleEditorChange = (content: string, _editor: any) => {
    setEditorContent(content);
  };

  const onVideoUploadClick = () => {
    const uplFile: HTMLInputElement | null = document.querySelector("#uplFile");
    uplFile?.click();
  };

  const onVideoUploadChange = async (
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!ev.target.files) {
      return;
    }
    // present uploading popup
    setUploading(true);
    // upload the file to the server and get back the sas link
    const res = await API_HELPER.UploadArticleFile(
      articleId || "",
      ev.target.files[0]
    );
    // append the video to the editor
    setEditorContent(editorContent + getVideoElement(res));
    // reset the file input
    ev.target.value = "";
    // hide the uploading popup
    setUploading(false);
    // present the success message
    setUploaded(true);
  };

  const handleImageUpload: UploadHandler = async (
    blobInfo: BlobInfo,
    _progress: ProgressFn
  ) => {
    return await API_HELPER.UploadArticleFile(articleId || "", blobInfo.blob());
  };

  return (
    <div style={{ width: "100%" }}>
      <ProgressToast open={uploading} success={false} loading={uploading} />
      <ConfirmationToast
        open={uploaded}
        closeCallback={handleClose}
        text="Successfully uploaded video and URL copied to clipboard"
        actionText="Undo"
        actionOnClick={onUndoClick}
        closeOnClick={handleClose}
      />
      <input
        type="file"
        id="uplFile"
        name="uplFile"
        accept="video/*"
        onChange={onVideoUploadChange}
        // hide the input fully on all browsers
        style={{ display: "none", position: "fixed", top: "-100em" }}
      />
      <Editor
        id="txtContent"
        tagName="txtContent"
        ref={editor}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        onEditorChange={handleEditorChange}
        initialValue={initialValue}
        value={editorContent}
        init={{
          width: "100%",
          height: height,
          menubar: true,
          plugins: EditorPlugins,
          font_family_formats: FontFamilyOptions,
          image_uploadtab: true,
          // allow the user to paste the images into the editor
          paste_data_images: true,
          toolbar: EditorToolbarOptions,
          content_style: EditorContentStyleOptions,
          block_unsupported_drop: true,
          automatic_uploads: true,
          file_picker_types: "file image media",
          images_upload_credentials: false,
          images_upload_handler: handleImageUpload,
          setup: (editor) => {
            editor.ui.registry.addButton("btnUploadVideo", {
              text: "Upload Video",
              onAction: onVideoUploadClick,
            });
          },
        }}
      />
    </div>
  );
};

export default TinyMCEEditor;
