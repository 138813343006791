import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid/models";
import { DeleteForever, RemoveRedEye } from "@mui/icons-material";
import TTCButton from "../button";
import { useDirectoriesWithArticleCount } from "../../hooks/useDirectories";

const DirectoryTable = () => {
  const navigate = useNavigate();
  const { directories } = useDirectoriesWithArticleCount();

  const columns: GridColDef[] = [
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <TTCButton
          variant="contained"
          text="EDIT"
          width={100}
          onClickHandler={() => {
            navigate(`/manage-directory/${params.row["id"]}`);
          }}
        />
      ),
    },
    {
      field: "preview",
      headerName: "View",
      width: 50,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <RemoveRedEye
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/article-list/${params.row["id"]}`);
          }}
        />
      ),
    },
    { field: "id", headerName: "ID", flex: 1 },
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "highlighted",
      headerName: "Highlighted",
      flex: 1,
      type: "boolean",
    },
    {
      field: "highlightedOrder",
      headerName: "Highlighted Order",
      flex: 1,
      type: "number",
    },
    {
      field: "articleCount",
      headerName: "Article Count",
      flex: 1,
      type: "number",
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   type: "boolean",
    //   align: "center",
    //   renderCell: (params: GridRenderCellParams) => (
    //     <DeleteForever
    //       style={{ cursor: "pointer" }}
    //       onClick={() => {
    //         navigate(`/article/${params.row["id"]}`);
    //       }}
    //     />
    //   ),
    // },
  ];

  return (
    <div style={{ height: 600, width: "80%" }}>
      <h2>Directories</h2>
      <DataGrid
        rows={directories}
        columns={columns}
        loading={directories.length === 0}
        pageSize={9}
        rowsPerPageOptions={[9]}
        columnVisibilityModel={{ id: true }}
        sortModel={[
          { field: "title", sort: "asc" },
          // { field: "updatedDate", sort: "desc" },
        ]}
      />
    </div>
  );
};

export default DirectoryTable;
