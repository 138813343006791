// import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { getAuth } from "../auth";

import Toolbar from "@mui/material/Toolbar";
import SignInButton from "../components/sign-in-button";

const Login = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Toolbar style={{ marginBottom: 50 }} />
      <SignInButton />
    </div>
  );
};

export default Login;
