import React from "react";
import { useParams } from "react-router-dom";
import SupportArticle from "../components/support-article";

import "../style/article.css";

const Article = () => {
  let { id } = useParams();
  return (
    <div className="article-container">
      <SupportArticle id={id ?? ""} />
    </div>
  );
};

export default Article;
