import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArticleLink from "../components/article-link";
import { IArticle } from "../models/article";
import { IDirectory } from "../models/directory";
import { API } from "../routing/api";
import { RoutePaths } from "../routing/routes";

const style: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const titleStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
};

const ArticleList = () => {
  let { id } = useParams();
  const [articleList, setArticleList] = useState<IArticle[]>([]);
  const [directory, setDirectory] = useState<IDirectory>();
  const navigate = useNavigate();

  useEffect(() => {
    const getDirectoryName = async (): Promise<void> => {
      try {
        const res = await axios.get(API.GetDirectoryById(id));
        setDirectory(res.data);
      } catch (err: any) {
        navigate(RoutePaths.Root, { replace: true, state: { error: true } });
      }
    };

    const getArticleList = async (): Promise<void> => {
      // get a set list of articles using the directory id
      const res = await axios.get(API.GetAllArticlesByDirectoryId(id));
      setArticleList(res.data);
    };

    getDirectoryName();
    getArticleList();
  }, [id, navigate]);

  return (
    <div style={style}>
      <div style={titleStyle}>
        <h1>{directory?.title}</h1>
        <div
          style={{
            width: `${directory?.title ? directory.title.length * 15 : 0}px`,
            marginTop: "-20px",
            height: "5px",
            background: "#B0DACE",
            alignSelf: "center",
          }}
        ></div>
        <p>{directory?.description}</p>
      </div>
      {articleList.map((article) => (
        <ArticleLink
          key={article.id}
          id={article.id}
          title={article.title ?? "UNKNOWN ARTICLE"}
        />
      ))}
    </div>
  );
};

export default ArticleList;
