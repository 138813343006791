import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Login from "../pages/login";
import { API, DEBUG } from "../routing/api";

export function useAuth() {
  const [authed, setAuthed] = useState<boolean>(false);

  useEffect(() => {
    try {
      const getAuthed = async () => {
        try {
          const res = await axios.get(API.Authorize());
          setAuthed(res.status === 200);
        } catch (err: any) {
          // ignored
        }
      };

      getAuthed();
    } catch (err: any) {
      // ignored
    }
  }, []);

  return authed;
}

const ProtectedRoutes = () => {
  const authed = useAuth();
  return DEBUG || authed ? <Outlet /> : <Login />;
};

export default ProtectedRoutes;
