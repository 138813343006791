import axios from "axios";
import { Buffer } from "buffer";

// HACK: DO NOT PUSH WITH TRUE
export const DEBUG = false;

export const API_URL = `${DEBUG ? "https://localhost:7284" : ""}${
  process.env.REACT_APP_API_URL
}`;

export const API = {
  // articles
  GetArticleById: (id: string | undefined) => `${API_URL}/article/${id}`,
  GetAllArticles: () => `${API_URL}/articles`,
  GetAllArticlesByDirectoryId: (directoryId: string | undefined) =>
    `${API_URL}/articles/${directoryId}`,
  CreateArticle: () => `${API_URL}/articles`,
  UpdateArticle: (id: string) => `${API_URL}/update-article/${id}`,
  DeleteArticle: (id: string) => `${API_URL}/delete/${id}`,
  GetHighlight: () => `${API_URL}/highlight`,
  UpdateHighlight: () => `${API_URL}/highlight`,
  // directories
  GetDirectoryById: (id: string | undefined) => `${API_URL}/directory/${id}`,
  GetAllDirectories: () => `${API_URL}/directories`,
  UpdateDirectory: (id: string) => `${API_URL}/update-directory/${id}`,
  // merged
  GetMerged: () => `${API_URL}/merged`,
  // blobs
  GetBlob: () => `${API_URL}/blob-by-id`,
  CreateBlob: (type: "article" | "directory") => `${API_URL}/${type}-blob`,
  // auth
  Authorize: () => `${API_URL}/authorize`,
  // update article view count
  UpdateArticleViews: (id: string) => `${API_URL}/update-view-count/${id}`,
  // search
  Search: () => `${API_URL}/search`,
};

export const API_HELPER = {
  GetArticleContent: async (contentId: string) => {
    const contentRes = await axios.post(API.GetBlob() + `?id=${contentId}`);
    var decoded = Buffer.from(contentRes.data.data, "base64");
    return decoded.toString("ascii").replaceAll("\\n", "");
  },
  GetSearchResults: async (searchCriteria: string[]) => {
    const res = await axios.post(API.Search(), {
      terms: searchCriteria,
    });

    return res.data;
  },
  _UploadFile: async (
    type: "article" | "directory",
    idValue: string,
    file: File | Blob
  ): Promise<string> => {
    // create a new form data object
    const formData = new FormData();
    // append the article id and file
    formData.append(`${type}Id`, idValue);
    formData.append("file", file);
    // post the data to the server
    const result = await axios.post(API.CreateBlob(type), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (result.status !== 200) {
      return "";
    }

    // return the url to the file
    return result.data.sasLink;
  },
  UploadDirectoryImage: async (
    directoryId: string,
    file: File | Blob
  ): Promise<string> => {
    return await API_HELPER._UploadFile("directory", directoryId, file);
  },
  UploadArticleFile: async (
    articleId: string,
    file: File | Blob
  ): Promise<string> => {
    return await API_HELPER._UploadFile("article", articleId, file);
  },
  DeleteArticle: async (articleId: string) => {
    await axios.delete(API.DeleteArticle(articleId));
  }
};
