import { Box, AppBar, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ChatBot } from "./components/chat-bot";
import Footer from "./components/footer";
import HeaderBar from "./components/header-bar";
import MainContent from "./components/main-content";

import "./style/App.css";
import { Chat } from "./components/gpt-chat-bot/Chat";

const containerStyle: SxProps<Theme> = {
  position: "relative",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

const appBarStyle: SxProps<Theme> = {
  zIndex: (theme) => theme.zIndex.drawer + 1,
  height: "80px",
  padding: 0,
};

const App = () => {
  return (
    <Box sx={containerStyle}>
      <AppBar position="fixed" sx={appBarStyle}>
        <HeaderBar />
      </AppBar>
      {/* <Chat /> */}
      {/* <ChatBot /> */}
      <MainContent />
      <Footer />
    </Box>
  );
};

export default App;
