import TTCButton from "../button";

const SignInButton = () => {
  return (
    <TTCButton
      variant="contained"
      text="Sign In"
      width={150}
      onClickHandler={() => {
        window.location.href = "/account/signin";
      }}
    />
  );
};

export default SignInButton;
