import { Save } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import green from "@mui/material/colors/green";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import {
  FormEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import SimpleToast from "../components/toast/simple-toast";
import UploadButton from "../components/upload-button";
import { useArticles } from "../hooks/useArticles";
import { useDirectories } from "../hooks/useDirectories";
import useHighlight from "../hooks/useHighlight";
import { EditorContentStyleOptions } from "../misc/editor-options";
import { API, API_HELPER } from "../routing/api";

const formStyle: React.CSSProperties = {
  margin: "50px 0 50px 0",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

interface UploadFiles {
  video: File | undefined;
  image1: File | undefined;
  image2: File | undefined;
  image3: File | undefined;
}

const ManageHighlight = () => {
  const { highlight } = useHighlight();
  const { articles } = useArticles();
  // const { directories } = useDirectories();
  const [title, setTitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [articleLink, setArticleLink] = useState<string>("");
  // const [directoryLink, setDirectoryLink] = useState<string>("");
  const [filesToUpload, setFilesToUpload] = useState<UploadFiles>({
    video: undefined,
    image1: undefined,
    image2: undefined,
    image3: undefined,
  });
  const [videoName, setVideoName] = useState<string>();
  // const [imageName1, setImageName1] = useState<string>();
  // const [imageName2, setImageName2] = useState<string>();
  // const [imageName3, setImageName3] = useState<string>();

  const [hover, setHover] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };

  const editor = useRef(null);

  useEffect(() => {
    setTitle(highlight?.title);
    setContent(highlight?.content);
    setArticleLink(highlight?.articleLink || "");
    // setDirectoryLink(highlight?.viewMoreLink || "");
    setVideoName(highlight?.video);
    // setImageName1(highlight?.image1);
    // setImageName2(highlight?.image2);
    // setImageName3(highlight?.image3);
  }, [highlight, success]);

  const titleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(ev.target.value);
  };

  const editorChange = (content: string, _editor: any) => {
    setContent(content);
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent
  ) => {
    e.preventDefault();
    // update highlight
    try {
      setUploading(true);
      const target = e.target as typeof e.target & {
        txtTitle: { value: string };
        txtContent: { value: string };
        cboArticle: { value: string };
        cboDirectory: { value: string };
      };

      const body = {
        title: target.txtTitle.value,
        content: target.txtContent.value,
        articleLink: target.cboArticle.value,
        video: videoName,
      };

      // upload the video and 3 images
      if (filesToUpload.video) {
        const url = await API_HELPER.UploadArticleFile(
          "@highlight@",
          filesToUpload.video
        );
        body.video = url;
      }

      const res = await axios.put(API.UpdateHighlight(), body);

      if (res.status === 200) {
        setAlertType("success");
        setSuccess(true);
      }
    } catch (err: any) {
      setAlertType("error");
      setSuccess(true);
    }
    setTimeout(() => {
      setUploading(false);
    }, 500);
  };

  return (
    <form style={formStyle} action="" method="post" onSubmit={handleFormSubmit}>
      <SimpleToast
        open={success}
        closeCallback={handleClose}
        alertType={alertType}
        text={
          alertType === "success"
            ? "Successfully updated."
            : "Something went wrong."
        }
      />
      <TextField
        id="txtTitle"
        name="txtTitle"
        style={{ width: 600, marginBottom: 10 }}
        variant="outlined"
        value={title || ""}
        onChange={titleChange}
        label="Title"
      />
      <Editor
        id="txtContent"
        tagName="txtContent"
        ref={editor}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        value={content}
        onEditorChange={editorChange}
        init={{
          height: "300px",
          width: "600px",
          menubar: false,
          plugins: ["link", "autolink", "preview"],
          toolbar: "undo redo | bold italic underline | preview",
          content_style: EditorContentStyleOptions,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "15px",
        }}
      >
        {/* TODO: Refactor me */}
        <FormControl sx={{ width: 600 }}>
          <InputLabel>Linked Article</InputLabel>
          <Select
            id="cboArticle"
            name="cboArticle"
            label="Linked Article"
            required
            value={articleLink}
            onChange={(ev) => {
              setArticleLink(ev.target.value);
            }}
          >
            {articles.map((article) => (
              <MenuItem key={article.id} value={article.id}>
                {article.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ width: 300 }}>
          <InputLabel>View More Directory</InputLabel>
          <Select
            id="cboDirectory"
            name="cboDirectory"
            label="View More Directory"
            required
            value={directoryLink}
            onChange={(ev) => {
              setDirectoryLink(ev.target.value);
            }}
          >
            {directories.map((directory) => (
              <MenuItem key={directory.id} value={directory.id}>
                {directory.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Box>
      <UploadButton
        text="Upload Video"
        type="text"
        accept="video/*"
        value={videoName || ""}
        onChangeHandler={(ev) => {
          if (ev.target.files !== null) {
            const file = ev.target.files[0];
            setFilesToUpload({
              ...filesToUpload,
              video: file,
            });
            setVideoName(file.name);
          }
        }}
      />
      {/* <UploadButton
        text="Upload Image1"
        type="both"
        accept="image/*"
        value={imageName1 || ""}
        onChangeHandler={(ev) => {
          if (ev.target.files !== null) {
            const file = ev.target.files[0];
            setFilesToUpload({
              ...filesToUpload,
              image1: file,
            });
            setImageName1(file.name);
          }
        }}
      />
      <UploadButton
        text="Upload Image2"
        type="both"
        accept="image/*"
        value={imageName2 || ""}
        onChangeHandler={(ev) => {
          if (ev.target.files !== null) {
            const file = ev.target.files[0];
            setFilesToUpload({
              ...filesToUpload,
              image2: file,
            });
            setImageName2(file.name);
          }
        }}
      />
      <UploadButton
        text="Upload Image3"
        type="both"
        accept="image/*"
        value={imageName3 || ""}
        onChangeHandler={(ev) => {
          if (ev.target.files !== null) {
            const file = ev.target.files[0];
            setFilesToUpload({
              ...filesToUpload,
              image3: file,
            });
            setImageName3(file.name);
          }
        }}
      /> */}
      <div style={{ marginTop: 15 }}>
        <Fab
          disabled={uploading}
          type="submit"
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          sx={{
            backgroundColor: hover ? "#B0DACE" : "#31296C",
            color: hover ? "#31296C" : "#B0DACE",
            // backup hover color
            "&:hover": {
              backgroundColor: "#B0DACE",
            },
          }}
          variant={uploading ? "circular" : "extended"}
          aria-label="edit"
        >
          <Save
            htmlColor={uploading ? "#31296C" : hover ? "#31296C" : "#B0DACE"}
            sx={{ mr: uploading ? 0 : 1 }}
          />
          {uploading ? (
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: "absolute",
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          ) : (
            "Save"
          )}
        </Fab>
      </div>
    </form>
  );
};

export default ManageHighlight;
