import { UploadFile } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useRef } from "react";

type PreviewType = "text" | "img" | "both";

interface PreviewSectionProps {
  type: PreviewType;
  value: string;
}

interface UploadButtonProps {
  text: string;
  type: PreviewType;
  accept: "video/*" | "image/*";
  value: string;
  margin?: number;
  onChangeHandler: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const PreviewSection = ({ type, value }: PreviewSectionProps) => {
  const TextSection = () => (
    <TextField
      InputProps={{
        disabled: true,
        style: {
          borderRight: "none",
        },
      }}
      fullWidth={true}
      value={value}
    />
  );

  const ImageSection = () => (
    <img
      src={value}
      alt="uploaded img"
      style={{
        objectFit: "scale-down",
        width: 56,
        border: "1px solid rgba(0, 0, 0, 0.26)",
      }}
    />
  );

  const bothSection = () => (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <ImageSection />
      <TextSection />
    </Box>
  );

  let Section = TextSection;

  switch (type) {
    case "text":
      Section = TextSection;
      break;
    case "img":
      Section = ImageSection;
      break;
    case "both":
      Section = bothSection;
      break;
  }

  return <Section />;
};

const UploadButton = ({
  text,
  type,
  accept,
  value,
  margin,
  onChangeHandler,
}: UploadButtonProps) => {
  const ref = useRef<HTMLInputElement>(null);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: 600,
        marginTop: margin || 5,
      }}
    >
      <PreviewSection type={type} value={value} />
      <Button
        variant="outlined"
        startIcon={<UploadFile />}
        style={{
          width: 300,
          height: 56,
          border: "1px solid rgba(0, 0, 0, 0.26)",
          borderLeft: "none",
          cursor: "pointer",
        }}
        type="button"
        onClick={() => ref.current?.click()}
      >
        {text}
        <input
          ref={ref}
          type="file"
          accept={accept}
          hidden
          onChange={onChangeHandler}
        />
      </Button>
    </Box>
  );
};

export default UploadButton;
