import DirectoryTable from "../components/directory-table";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  marginTop: "20px",
};

const AdminDirectories = () => {
  return (
    <div style={containerStyle}>
      <DirectoryTable />
    </div>
  );
};

export default AdminDirectories;
