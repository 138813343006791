import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  InputAdornment,
  Autocomplete,
  Box,
} from "@mui/material";
import { Search, Star } from "@mui/icons-material";
import { API_HELPER } from "../../routing/api";
import { ISearchResult } from "../../models/search-result";

const style: React.CSSProperties = {
  textAlign: "center",
  width: "100%",
  height: "100%",
  backgroundImage: `linear-gradient(#31296C, #30296BBF)`,
};

const SearchHeader = () => {
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);

  const navigate = useNavigate();

  const handleNavigate = (_event: React.SyntheticEvent, value: any) => {
    value = value as ISearchResult;
    navigate(`/article/${value.id}`);
  };

  let delayTimer: NodeJS.Timeout;

  const onChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    // min 3 characters
    if (ev.target.value.length < 3) {
      return;
    }

    const terms = ev.target.value.trim().split(" ");

    // clear the timer every time the input changes
    clearTimeout(delayTimer);
    // add timeout between requests
    delayTimer = setTimeout(async () => {
      const res = await API_HELPER.GetSearchResults(terms);
      setSearchResults(res);
    }, 250);
  };

  return (
    <div style={style}>
      <Typography
        variant="h5"
        style={{ padding: 15, marginTop: 20, color: "white" }}
      >
        How can we help?
      </Typography>
      <Autocomplete
        freeSolo={true}
        autoHighlight={true}
        clearOnBlur={true}
        onChange={handleNavigate}
        options={searchResults}
        getOptionLabel={(result) => (result as ISearchResult).title}
        filterOptions={(x) => x}
        renderOption={(props, options) => {
          return (
            <Box component="li" {...props}>
              {options.isPopular === true && <Star />}&nbsp;{options.title}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="input-with-icon-textfield"
            style={{
              width: 400,
              marginBottom: 25,
              backgroundColor: "#8569aa",
              border: "1px solid white",
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "white",
              },
            }}
            onChange={onChange}
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search htmlColor="white" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

export default SearchHeader;
