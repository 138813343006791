import SearchHeader from "../search-header";
import HighlightedDirectories from "../highlighted-directories";
import HighlightedVideo from "../highlighted-video";
import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { IArticle } from "../../models/article";
import axios from "axios";
import { API } from "../../routing/api";
import { useNavigate } from "react-router-dom";
import TTCButton from "../button";

const style: React.CSSProperties = {
  margin: 0,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
};

const SupportDirectory = () => {
  const [popularArticles, setPopularArticles] = useState<IArticle[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const getPopularArticles = async () => {
      try {
        const res = await axios.get(API.GetAllArticles());
        const popularArticles = (res.data as IArticle[])
          // .filter((a) => a.popular)
          .sort((a, b) => b.viewCount - a.viewCount)
          .splice(0, 5);
        setPopularArticles(popularArticles);
      } catch (err: any) {
        // ignored
      }
    };

    getPopularArticles();
  }, []);

  return (
    <div style={{ ...style, flexDirection: "column" }}>
      {/* Search bar */}
      <SearchHeader />
      {/* Quick start guides */}
      <HighlightedDirectories style={style} />
      {/* Highlighted video tutorial */}
      {/* <HighlightedVideo /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          height: "100%",
          width: "100%",
          textAlign: "center",
          padding: "20px 0 30px 0",
        }}
      >
        <Typography variant="h5" fontWeight={"bold"}>
          Popular searches & support topics
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: 1200,
            textAlign: "center",
          }}
        >
          {popularArticles.map((article) => (
            <TTCButton
              key={article.id}
              variant="contained"
              style={{
                width: 225,
                height: 50,
                margin: 5,
                backgroundColor: "#f5f5f5",
                border: "1px solid #cecece",
              }}
              text={article.title}
              onClickHandler={() => navigate(`/article/${article.id}`)}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: 20,
          backgroundColor: "#f5f5f5",
        }}
      >
        <iframe
          title="security"
          src="https://securityscorecard.com/security-rating/badge/ttc-uk.com"
          width="256"
          height="100"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default SupportDirectory;
