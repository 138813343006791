import axios from "axios";
import { useEffect, useState } from "react";
import { IHighlight } from "../models/highlight";
import { API } from "../routing/api";

const useHighlight = () => {
  const [highlight, setHighlight] = useState<IHighlight>();

  useEffect(() => {
    const getHighlight = async () => {
      const res = await axios.get(API.GetHighlight());
      setHighlight(res.data);
    };
    getHighlight();
  }, []);

  return { highlight, setHighlight };
};

export default useHighlight;
