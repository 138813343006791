export const RoutePaths = {
  Root: "/",
  Admin: "/admin",
  AdminPanel: "/admin-panel",
  ArticleList: "/article-list/:id",
  ManageDirectory: "/manage-directory/:id",
  ManageArticle: "/manage-article/:id",
  ManageHighlight: "/manage-highlight",
  EditArticle: "/:articleId",
  Article: "/article/:id",
};
