import axios from "axios";
import { useEffect, useState } from "react";
import { IArticle } from "../models/article";
import { API } from "../routing/api";

export const useArticles = () => {
  const [articles, setArticles] = useState<IArticle[]>([]);

  useEffect(() => {
    const getArticles = async () => {
      const res = await axios.get(API.GetAllArticles());
      setArticles(
        (res.data as IArticle[]).sort((a, b) => {
          const textA: string = a.title?.toLocaleUpperCase() || "";
          const textB: string = b.title?.toLocaleUpperCase() || "";
          return textA.localeCompare(textB);
        })
      );
    };

    getArticles();
  }, []);

  return { articles, setArticles };
};

export const useArticlesByDirectory = (directoryId: string | undefined) => {
  const { articles } = useArticles();

  return { articles: articles.filter((a) => a.directory === directoryId) };
};
