import { Button, IconButton, Snackbar, SnackbarOrigin } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { ToastProps } from "./IToastProps";

interface ActionProps {
  actionText: string;
  actionOnClick: (event: React.SyntheticEvent | Event, reason?: string) => void;
  closeOnClick: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

interface ConfirmationToastProps extends ToastProps, ActionProps {
  position?: SnackbarOrigin;
}

const Action = ({ actionText, actionOnClick, closeOnClick }: ActionProps) => {
  return (
    <>
      <Button color="warning" size="small" onClick={actionOnClick}>
        {actionText}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeOnClick}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );
};

const ConfirmationToast = ({
  open,
  text,
  position,
  closeCallback,
  actionText,
  actionOnClick,
  closeOnClick,
}: ConfirmationToastProps) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={position || { vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      message={text}
      onClose={closeCallback}
      action={Action({
        actionText: actionText,
        actionOnClick: actionOnClick,
        closeOnClick: closeOnClick,
      })}
    />
  );
};

export default ConfirmationToast;
