import * as React from "react";
import { useNavigate } from "react-router-dom";
import ArticleTable from "../components/article-table";
import TTCButton from "../components/button";
import CreateArticleForm from "../components/create-article-form";
import CreateDirectoryForm from "../components/create-directory-form";
import { RoutePaths } from "../routing/routes";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const formStyle: React.CSSProperties = {
  width: "60%",
  display: "flex",
  flexDirection: "row",
};

const AdminPanel = () => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(RoutePaths.ManageHighlight);
  };

  return (
    <div style={containerStyle}>
      <h1>Admin Panel</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TTCButton
          variant="contained"
          text="Adjust Highlighted Video Section"
          onClickHandler={onClickHandler}
        />
      </div>
      <div style={formStyle}>
        {/* Create Directory */}
        <CreateDirectoryForm />
        {/* Create Article */}
        <CreateArticleForm />
      </div>
      <hr style={{ width: "70%", marginTop: "20px" }} />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          width: "60%",
        }}
      >
        <TTCButton
          variant="contained"
          text="Manage Directories"
          onClickHandler={() => navigate("/admin-directories")}
        />
        <TTCButton
          variant="contained"
          text="Manage Articles"
          onClickHandler={() => navigate("/admin-articles")}
        />
      </div>
    </div>
  );
};

export default AdminPanel;
