import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { IDirectory } from "../../models/directory";
import TTCButton from "../button";
import { useNavigate } from "react-router-dom";
import { generateId } from "../../misc/utility";
import { API } from "../../routing/api";

const formStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "& > :not(style)": { m: 1, width: "25ch" },
};

const CreateArticleForm = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [directories, setDirectories] = useState<IDirectory[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    getDirectories();
  }, []);

  const getDirectories = async () => {
    const res = await axios.get(API.GetAllDirectories());
    setDirectories(res.data);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setSubmitting(true);
    e.preventDefault();
    try {
      const target = e.target as typeof e.target & {
        txtArticleName: { value: string };
        cboParent: { value: string };
      };
      const txtArticleName = target.txtArticleName.value;
      const cboParent = target.cboParent.value;

      const id = generateId(txtArticleName);

      // create a new article
      const res = await axios.post(API.GetAllArticles(), {
        id: id,
        title: txtArticleName,
        directory: cboParent,
      });

      if (res.status === 200) {
        // redirect to /manage-article with id
        navigate(`/manage-article/${id}`);
      }
    } catch (err: any) {
      if (err.response.status === 409) {
        setErrorMessage(err.response.data.error);
      }
    }
    setSubmitting(false);
  };

  return (
    <Box
      component="form"
      sx={formStyle}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <h3>Create New Article</h3>
      <TextField
        type="text"
        id="txtArticleName"
        name="txtArticleName"
        label="New Article Name"
        error={errorMessage !== ""}
        helperText={errorMessage}
        variant="outlined"
        required={true}
        style={{ width: "300px" }}
      />
      <TextField
        select
        id="cboParent"
        name="cboParent"
        label="Parent Directory"
        required={true}
        helperText="Please select a parent directory"
        style={{ width: "300px" }}
        variant="outlined"
        defaultValue={""}
      >
        {directories.map((directory) => (
          <MenuItem key={directory.id} value={directory.id}>
            {directory.title}
          </MenuItem>
        ))}
      </TextField>
      <TTCButton
        variant="contained"
        type="submit"
        text={submitting ? "Creating..." : "Create"}
        disabled={submitting}
      />
    </Box>
  );
};

export default CreateArticleForm;
