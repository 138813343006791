import axios from "axios";
import { useEffect, useState } from "react";
import { IDirectory } from "../models/directory";
import { API } from "../routing/api";
import { useArticles } from "./useArticles";

export const useDirectories = () => {
  const [directories, setDirectories] = useState<IDirectory[]>([]);

  useEffect(() => {
    const getDirectories = async () => {
      const res = await axios.get(API.GetAllDirectories());
      setDirectories(res.data);
    };
    getDirectories();
  }, []);

  return { directories, setDirectories };
};

export const useDirectoriesWithArticleCount = () => {
  const { directories } = useDirectories();
  const { articles } = useArticles();

  const combined = directories.map((d) => ({
    ...d,
    articleCount: articles.filter((a) => a.directory === d.id).length,
  }));

  return { directories: combined };
};

export const useDirectoryById = (id: string | undefined) => {
  const { directories } = useDirectories();

  return { directory: directories.find((d) => d.id === id) };
};
