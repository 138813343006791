import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import SimpleToast from "../toast/simple-toast";
import { generateId } from "../../misc/utility";
import { API } from "../../routing/api";

const formStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  "& > :not(style)": { m: 1, width: "25ch" },
};

const buttonStyle: SxProps<Theme> = {
  fontWeight: "bold",
  backgroundColor: "#B0DACE",
  color: "#1f1b24",
  "&:hover": {
    backgroundColor: "#8dcab8",
    color: "#1f1b24",
  },
};

const CreateDirectoryForm = () => {
  const [directoryName, setDirectoryName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const target = e.target as typeof e.target & {
        txtName: { value: string };
      };
      const txtName = target.txtName.value;

      const res = await axios.post(API.GetAllDirectories(), {
        id: generateId(txtName),
        title: txtName,
      });

      if (res.status === 200) {
        setOpen(true);
        setDirectoryName("");
      }
    } catch (err: any) {
      if (err.response.status === 409) {
        setErrorMessage(err.response.data.error);
      }
    }
  };

  // TODO: Add ability to upload image
  return (
    <Box
      component="form"
      sx={formStyle}
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <h3>Create New Directory</h3>
      <SimpleToast
        open={open}
        closeCallback={handleClose}
        alertType="success"
        text="Successfully created a new directory."
      />
      <TextField
        type="text"
        id="txtName"
        name="txtName"
        label="New Directory Name"
        value={directoryName}
        onChange={(ev) => {
          setDirectoryName(ev.target.value);
        }}
        error={errorMessage !== ""}
        helperText={errorMessage}
        variant="outlined"
        required={true}
        style={{ width: "300px" }}
      />
      <Button variant="contained" sx={buttonStyle} type="submit">
        Create
      </Button>
    </Box>
  );
};

export default CreateDirectoryForm;
