import { Box, Divider } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { RoutePaths } from "../../routing/routes";
import TTCButton from "../button";
import ResourceMenu from "../resources-menu";
import SignInButton from "../sign-in-button";

const toolbarStyle: SxProps<Theme> = {
  padding: 0,
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginLeft: "-110px",
  alignItems: "center",
  color: "white",
};

const VerticalDivider = () => (
  <Divider
    orientation="vertical"
    flexItem
    style={{ background: "white", marginRight: 10 }}
  />
);

const HeaderBar = () => {
  const isAuthed = useAuth();
  const navigate = useNavigate();

  const routeChange = () => {
    navigate("/");
  };

  return (
    <Toolbar sx={toolbarStyle}>
      <img
        style={{ height: "300px", cursor: "pointer" }}
        src="/logo512.png"
        alt="Continuum Logo"
        onClick={routeChange}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResourceMenu />
        <VerticalDivider />
        {isAuthed && (
          <>
            <TTCButton
              text="Admin Panel"
              variant="contained"
              width={150}
              style={{ marginRight: 10 }}
              onClickHandler={() => navigate(RoutePaths.AdminPanel)}
            />
            <VerticalDivider />
          </>
        )}
        {/* <TTCButton
          text="Contact Support"
          variant="contained"
          width={200}
          style={{ marginRight: 10 }}
        /> */}
        {!isAuthed ? <SignInButton /> : <span>Logged in - Editor</span>}
      </Box>
    </Toolbar>
  );
};

export default HeaderBar;
