export declare type ProgressFn = (percent: number) => void;

export interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

export declare type UploadHandler = (
  blobInfo: BlobInfo,
  progress: ProgressFn
) => Promise<string>;

export const EditorPlugins = [
  "link",
  "autolink",
  "image",
  "media",
  "table",
  "preview",
];

export const EditorToolbarOptions =
  "undo redo | styleselect | fontfamily | " +
  "bold italic | backcolor | alignleft aligncenter " +
  "alignright alignjustify | bullist numlist outdent indent | image btnUploadVideo media | " +
  "removeformat | help | preview";

export const EditorContentStyleOptions =
  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }";

export const FontFamilyOptions =
  "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; " +
  "Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; " +
  "Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; " +
  "Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; " +
  "Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; " +
  "Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; " +
  "Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats";
