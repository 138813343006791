import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TTCButton from "../components/button";
import SimpleToast from "../components/toast/simple-toast";
import UploadButton from "../components/upload-button";
import { useDirectoryById } from "../hooks/useDirectories";
import { API, API_HELPER } from "../routing/api";

const style: React.CSSProperties = {
  marginTop: "50px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const ManageDirectory = () => {
  let { id } = useParams();

  const { directory } = useDirectoryById(id);

  const [success, setSuccess] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [characterCount, setCharacterCount] = useState<number>();
  const [image, setImage] = useState<string>();
  const [highlight, setHighlighted] = useState<boolean>();
  const [highlightedOrder, setHighlightedOrder] = useState<number | string>();

  useEffect(() => {
    setTitle(directory?.title);
    setDescription(directory?.description);
    setCharacterCount(directory?.description.length);
    setImage(directory?.image);
    setHighlighted(directory?.highlighted);
    setHighlightedOrder(directory?.highlightedOrder);
  }, [directory]);

  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };

  const handleImageUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.files !== null) {
      const file = ev.target.files[0];
      const url = await API_HELPER.UploadDirectoryImage(
        `DIRECTORY_${directory?.id}`,
        file
      );
      setImage(url);
    }
  };

  const handleCheckChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setHighlighted(ev.target.checked);
  };

  const handleOrderChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setHighlightedOrder(ev.target.value);
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent
  ) => {
    e.preventDefault();

    try {
      const body = {
        title: title,
        description: description,
        image: image,
        highlighted: highlight,
        highlightedOrder: highlightedOrder,
      };

      const res = await axios.put(API.UpdateDirectory(id || ""), body);

      if (res.status === 200) {
        setAlertType("success");
        setSuccess(true);
      }
    } catch (err: any) {
      setAlertType("error");
      setSuccess(true);
    }
  };

  return (
    <form style={style} method="post" onSubmit={handleFormSubmit}>
      <SimpleToast
        open={success}
        closeCallback={handleClose}
        alertType={alertType}
        text={
          alertType === "success"
            ? "Successfully updated."
            : "Something went wrong."
        }
      />
      <TextField
        id="txtTitle"
        name="txtTitle"
        sx={{
          width: 600,
        }}
        variant="outlined"
        value={title || ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          setTitle(ev.target.value)
        }
        label={"Title"}
        required={true}
      />
      <TextField
        id="txtDescription"
        name="txtDescription"
        multiline={true}
        value={description || ""}
        variant="outlined"
        sx={{ width: 600, marginTop: "15px" }}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          setCharacterCount(ev.target.value.length);
          setDescription(ev.target.value);
        }}
        inputProps={{ maxLength: 100 }}
        label={`Description - Remaining characters: ${
          100 - (characterCount || 0)
        }`}
        required={true}
      />
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UploadButton
          text="Upload Directory Image"
          type="both"
          accept="image/*"
          value={image || ""}
          margin={15}
          onChangeHandler={handleImageUpload}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={highlight || false}
              color="primary"
              onChange={handleCheckChange}
              required={true}
            />
          }
          label="Quickstart guides?"
        />
        {highlight && (
          <TextField
            type="number"
            variant="outlined"
            value={highlightedOrder}
            onChange={handleOrderChange}
            label="Highlighted Order (Smaller first)"
            size="small"
            required={true}
          />
        )}
      </Box>
      <TTCButton
        variant="contained"
        text="Save"
        type="submit"
        style={{ marginTop: "15px", width: 600 }}
      />
    </form>
  );
};

export default ManageDirectory;
