import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import Newspaper from "@mui/icons-material/Newspaper";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IDirectory } from "../../models/directory";
import { API } from "../../routing/api";
import { SxProps, Theme } from "@mui/material/styles";

interface ISet {
  id: string;
  title: string;
}

interface IGroup extends IDirectory {
  articles: ISet[];
}

interface ArticleItemProps extends ISet {
  onClickHandler: (id: string) => void;
  active?: boolean;
}

const EmptyItem = () => {
  return (
    <ListItemButton disabled>
      <ListItemText
        primaryTypographyProps={{ sx: { textAlign: "center" } }}
        primary="No articles"
      />
    </ListItemButton>
  );
};

const ArticleItem = ({
  id,
  title,
  active,
  onClickHandler,
}: ArticleItemProps) => {
  let sx: SxProps<Theme> = {};
  if (active) {
    sx = {
      backgroundColor: "#B0DACE",
      color: "#1F1B24",
      "&:hover": {
        backgroundColor: "#8dcab8",
      },
    };
  }

  return (
    <ListItemButton key={id} onClick={() => onClickHandler(id)} sx={{ ...sx }}>
      <ListItemIcon>
        <Newspaper />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

const ResourceMenu = () => {
  const [merged, setMerged] = useState<IGroup[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDirectory, setOpenDirectory] = useState<string>();

  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);

  useEffect(() => {
    const getMerged = async () => {
      const res = await axios.get(API.GetMerged());
      setMerged(res.data);
    };
    getMerged();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDirectoryClick = (id: string) => {
    setOpenDirectory(openDirectory === id ? "" : id);
  };

  const handleArticleClick = (id: string) => {
    // close the menu
    setAnchorEl(null);
    setOpenDirectory("");
    // navigate to the article
    navigate(`/article/${id}`);
  };

  return (
    <Box>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ color: "white", marginRight: 10 }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Resources
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {merged.map(
          (m) =>
            m.articles.length > 0 && (
              <List
                key={m.id}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  key={m.id}
                  onClick={() => handleDirectoryClick(m.id)}
                >
                  <ListItemText
                    primary={m.title}
                    primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
                  />
                  {openDirectory === m.id ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={openDirectory === m.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {m.articles === null || m.articles.length === 0 ? (
                      <EmptyItem />
                    ) : (
                      m.articles?.map((art) => (
                        <ArticleItem
                          key={art.id}
                          id={art.id}
                          title={art.title}
                          active={location.pathname === `/article/${art.id}`}
                          onClickHandler={handleArticleClick}
                        />
                      ))
                    )}
                  </List>
                </Collapse>
              </List>
            )
        )}
      </Menu>
    </Box>
  );
};

export default ResourceMenu;
