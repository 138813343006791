import ArticleTable from "../components/article-table";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  marginTop: "20px",
};

const AdminArticles = () => {
  return (
    <div style={containerStyle}>
      <ArticleTable />
    </div>
  );
};

export default AdminArticles;
