import {
  Box,
  CircularProgress,
  Fab,
  Snackbar,
  SxProps,
  Theme,
} from "@mui/material";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import CloudUpload from "@mui/icons-material/CloudUpload";

interface ActionProps {
  success: boolean;
  loading: boolean;
}

const Action = ({ success, loading }: ActionProps) => {
  const buttonSx: SxProps<Theme> = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Fab aria-label="save" color="primary" sx={buttonSx}>
        {success ? <CheckIcon /> : <CloudUpload />}
      </Fab>
      {loading && (
        <CircularProgress
          size={68}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

interface ProgressToastProps extends ActionProps {
  open: boolean;
}

const ProgressToast = ({ open, success, loading }: ProgressToastProps) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={Action({ success, loading })}
      message="Uploading..."
    />
  );
};

export default ProgressToast;
