import React, { FormEvent, FormEventHandler, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Autocomplete, Box, Chip, MenuItem, TextField } from "@mui/material";
import TTCButton from "../components/button";
import TinyMCEEditor from "../components/tiny-mce-editor";
import SimpleToast from "../components/toast/simple-toast";
import { IArticle } from "../models/article";
import { API, API_HELPER } from "../routing/api";
import { useDirectories } from "../hooks/useDirectories";

const style: React.CSSProperties = {
  marginTop: "30px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const ManageArticles = () => {
  let { id } = useParams();

  const { directories } = useDirectories();

  const emptyArray: string[] = [];

  const [articleTitle, setArticleTitle] = useState<string | undefined>("");
  const [articleDirectory, setArticleDirectory] = useState<string>();
  const [articleText, setArticleText] = useState<string | undefined>("");
  const [articleTags, setArticleTags] = useState<string[]>(emptyArray);
  const [success, setSuccess] = useState<boolean>(false);
  const [alertType, setAlertType] = useState<"success" | "error">("success");

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };

  useEffect(() => {
    const getArticle = async () => {
      try {
        const res = await axios.get(API.GetArticleById(id));
        const { title, directory, content, tags }: IArticle = res.data;
        setArticleTitle(title);
        setArticleTags(tags || []);
        setArticleDirectory(directory);

        const contentRes = await API_HELPER.GetArticleContent(content || "");
        setArticleText(contentRes);
      } catch (err: any) {}
    };

    getArticle();
  }, [id]);

  const handleTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setArticleTitle(ev.target.value);
  };

  const handleDirectoryChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setArticleDirectory(ev.target.value);
  };

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = async (
    e: FormEvent
  ) => {
    e.preventDefault();
    // update article
    try {
      const target = e.target as typeof e.target & {
        txtTitle: { value: string };
        txtContent: { value: string };
      };
      const body = {
        title: target.txtTitle.value,
        directory: articleDirectory,
        content: target.txtContent.value,
        tags: articleTags,
      };

      const res = await axios.put(API.UpdateArticle(id ?? ""), body);

      if (res.status === 200) {
        setAlertType("success");
        setSuccess(true);
      }
    } catch (err: any) {
      setAlertType("error");
      setSuccess(true);
    }
  };

  return (
    <form style={style} action="" method="post" onSubmit={handleFormSubmit}>
      <SimpleToast
        open={success}
        closeCallback={handleClose}
        alertType={alertType}
        text={
          alertType === "success"
            ? "Successfully updated."
            : "Something went wrong."
        }
      />
      <TextField
        id="txtTitle"
        name="txtTitle"
        fullWidth={true}
        variant="outlined"
        value={articleTitle}
        onChange={handleTitleChange}
        label={"Title"}
      />
      <TinyMCEEditor articleId={id} initialValue={articleText} height={600} />
      <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <TextField
          select={true}
          id="cboParent"
          name="cboParent"
          label="Parent Directory"
          required={true}
          fullWidth={true}
          helperText="Please select a parent directory"
          style={{ width: "300px" }}
          variant="outlined"
          value={articleDirectory || ""}
          onChange={handleDirectoryChange}
        >
          {directories.map((directory) => (
            <MenuItem
              key={directory.id}
              value={directory.id}
              selected={directory.id === articleDirectory}
            >
              {directory.title}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          fullWidth={true}
          multiple={true}
          id="txtTags"
          options={emptyArray}
          value={articleTags}
          onChange={(_, newTag) => {
            setArticleTags(newTag);
          }}
          freeSolo={true}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Press [ENTER] to add a tag"
              placeholder="Tags"
            />
          )}
        />
      </Box>
      <TTCButton variant="contained" text="Save" type="submit" />
    </form>
  );
};

export default ManageArticles;
