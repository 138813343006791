import { useState } from "react";
import { useLocation } from "react-router-dom";
import SupportDirectory from "../components/support-directory";
import SimpleToast from "../components/toast/simple-toast";

interface HomeProps {
  error?: boolean;
}

const Home = () => {
  const location = useLocation();
  const state = location.state as HomeProps;
  let { error } = state || false;
  const [errorVisible, setErrorVisible] = useState<boolean>(error || false);
  return (
    <div>
      <SimpleToast
        open={errorVisible || false}
        alertType={"error"}
        text={"404 Page not found"}
        closeCallback={() => setErrorVisible(false)}
      />
      <SupportDirectory />
    </div>
  );
};

export default Home;
