import React from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";

interface ArticleLinkProps {
  id: string;
  title: string;
}

const style: React.CSSProperties = {
  // make the width fit content only and not full width
  float: "left",
  clear: "both",
  width: "auto",
  color: theme.palette.primary.main,
  fontWeight: "bold",
  textDecorationLine: "none",
  listStyle: "none",
  textAlign: "center",
};

const ArticleLink = ({ id, title }: ArticleLinkProps) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(`/article/${id}`);
  };
  return (
    <div style={style} onClick={clickHandler}>
      <p style={{ cursor: "pointer", textAlign: "center" }}>{title}</p>
    </div>
  );
};

export default ArticleLink;
