import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";

import "../../style/article.css";
import { API, API_HELPER } from "../../routing/api";
import { useAuth } from "../../auth";
import { RoutePaths } from "../../routing/routes";
import { IArticle } from "../../models/article";

const SupportArticle = ({ id }: { id: string }) => {
  const isAuthed = useAuth();
  const navigate = useNavigate();
  const [article, setArticle] = useState<IArticle>();
  const [content, setContent] = useState<string>();

  useEffect(() => {
    const getArticle = async (id: string) => {
      try {
        const articleRes = await axios.get(API.GetArticleById(id));
        setArticle(articleRes.data);
        const contentRes = await API_HELPER.GetArticleContent(
          articleRes.data.content
        );
        setContent(contentRes);
      } catch (err: any) {
        navigate(RoutePaths.Root, { replace: true, state: { error: true } });
      }
    };

    getArticle(id);

    // update view count
    updateViewCount(id);
  }, [id, navigate]);

  const updateViewCount = async (id: string) =>
    await axios.put(API.UpdateArticleViews(id));

  return (
    <div className="article">
      <h1>{article?.title!}</h1>
      <hr />
      <div
        dangerouslySetInnerHTML={{ __html: content || "No content found" }}
      ></div>
      {isAuthed && (
        <div style={{ position: "fixed", bottom: 30, right: 30 }}>
          <Fab
            style={{ backgroundColor: "#31296C" }}
            aria-label="edit"
            onClick={() => navigate(`/manage-article/${id}`)}
          >
            <EditIcon htmlColor="#B0DACE" />
          </Fab>
        </div>
      )}
    </div>
  );
};

export default SupportArticle;
