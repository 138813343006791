import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid/models";
import { DeleteForever, RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import TTCButton from "../button";
import { dateFormat } from "../../misc/utility";
import { useArticles } from "../../hooks/useArticles";
import { API_HELPER } from "../../routing/api";

const dateGetter = (params: GridValueGetterParams) =>
  moment.utc(params.value).format(dateFormat);

const DirectoryTable = () => {
  const navigate = useNavigate();
  const { articles } = useArticles();

  const columns: GridColDef[] = [
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <TTCButton
          variant="contained"
          text="EDIT"
          width={100}
          onClickHandler={() => {
            navigate(`/manage-article/${params.row["id"]}`);
          }}
        />
      ),
    },
    {
      field: "preview",
      headerName: "View",
      width: 50,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <RemoveRedEye
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/article/${params.row["id"]}`);
          }}
        />
      ),
    },
    { field: "id", headerName: "ID", flex: 1 },
    { field: "title", headerName: "Title", flex: 1 },
    { field: "directory", headerName: "Directory", flex: 1 },
    { field: "tags", headerName: "Tags", flex: 1 },
    // TODO: Make this field editable
    { field: "popular", headerName: "Popular", flex: 1, type: "boolean" },
    {
      field: "createdDate",
      type: "dateTime",
      headerName: "Created",
      flex: 1,
      valueGetter: dateGetter,
    },
    {
      field: "updatedDate",
      type: "dateTime",
      headerName: "Updated",
      flex: 1,
      valueGetter: dateGetter,
    },
    {
      field: "delete",
      headerName: "Delete",
      type: "boolean",
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <DeleteForever
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const confirmation = window.confirm(
              "Are you sure you wish to delete this article?"
            );
            if (confirmation) {
              await API_HELPER.DeleteArticle(params.row["id"]);
              navigate(0);
            }
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: "80%" }}>
      <h2>Articles</h2>
      <DataGrid
        rows={articles}
        columns={columns}
        loading={articles.length === 0}
        pageSize={9}
        rowsPerPageOptions={[9]}
        columnVisibilityModel={{ id: true }}
        sortModel={[
          { field: "title", sort: "asc" },
          // { field: "updatedDate", sort: "desc" },
        ]}
      />
    </div>
  );
};

export default DirectoryTable;
