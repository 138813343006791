import { Box, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IDirectory } from "../../models/directory";
import TTCButton from "../button";

const style: React.CSSProperties = {
  flex: "0 0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "400px",
  height: "255px",
  backgroundColor: "white",
  textAlign: "center",
};

const imageSize = 60;

const DirectoryPanel = ({ id, image, title, description }: IDirectory) => {
  const navigate = useNavigate();
  const routeChange = () => {
    const path = `/article-list/${id}`;
    navigate(path);
  };
  return (
    <Box style={style}>
      <Card
        variant="outlined"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box>
            <img
              src={image}
              alt={title}
              style={{
                height: imageSize,
                width: imageSize,
              }}
              loading="lazy"
            />
            <Typography
              variant="h2"
              sx={{ fontSize: 20, fontWeight: "bold" }}
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="body1" sx={{ mb: "5px" }}>
              {description}
            </Typography>
          </Box>
          <TTCButton
            variant="contained"
            type="button"
            text="Read Guide"
            onClickHandler={routeChange}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default DirectoryPanel;
