import Button from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material/styles";
import { MouseEventHandler } from "react";

interface TTCButtonProps {
  variant: "text" | "outlined" | "contained";
  type?: "submit" | "reset" | "button" | undefined;
  width?: number;
  style?: React.CSSProperties;
  text: string | undefined;
  disabled?: boolean;
  onClickHandler?: MouseEventHandler | undefined;
}

const buttonStyle: SxProps<Theme> = {
  fontWeight: "bold",
  backgroundColor: "#B0DACE",
  color: "#1f1b24",
  "&:hover": {
    backgroundColor: "#8dcab8",
    color: "#1f1b24",
  },
  boxShadow: "none",
};

const TTCButton = ({
  variant,
  type,
  text,
  style,
  width,
  disabled,
  onClickHandler,
}: TTCButtonProps) => {
  return (
    <Button
      variant={variant}
      sx={{ ...buttonStyle, width: width ?? 250 }}
      style={style}
      type={type}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default TTCButton;
