import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoutes from "../../auth";
import AdminArticles from "../../pages/admin-articles";
import AdminDirectories from "../../pages/admin-directories";
import AdminPanel from "../../pages/admin-panel";
import Article from "../../pages/article";
import ArticleList from "../../pages/article-list";
import Home from "../../pages/home";
import Login from "../../pages/login";
import ManageArticles from "../../pages/manage-article";
import ManageDirectory from "../../pages/manage-directory";
import ManageHighlight from "../../pages/manage-highlight";
import { RoutePaths } from "../../routing/routes";

const MainContent = () => {
  return (
    <Box component="main" sx={{ flexGrow: "1" }}>
      <Toolbar />
      <div>
        <Routes>
          <Route path={RoutePaths.Root} element={<Home />} />
          <Route path={RoutePaths.Admin} element={<Login />} />
          <Route path={RoutePaths.Article} element={<Article />} />
          <Route path={RoutePaths.ArticleList} element={<ArticleList />} />
          <Route element={<ProtectedRoutes />}>
            <Route path={RoutePaths.AdminPanel} element={<AdminPanel />} />
            <Route path={"/admin-articles"} element={<AdminArticles />} />
            <Route path={"/admin-directories"} element={<AdminDirectories />} />
            <Route
              path={RoutePaths.ManageDirectory}
              element={<ManageDirectory />}
            />
            <Route
              path={RoutePaths.ManageArticle}
              element={<ManageArticles />}
            />
            <Route
              path={RoutePaths.ManageHighlight}
              element={<ManageHighlight />}
            />
          </Route>
          {/* <Route
            path={"*"}
            element={
              <Navigate to={RoutePaths.Root} replace state={{ error: true }} />
            }
          /> */}
        </Routes>
      </div>
    </Box>
  );
};

export default MainContent;
