import { Box, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { IDirectory } from "../../models/directory";
import { API } from "../../routing/api";
import DirectoryPanel from "../directory-panel";

interface HighlightedDirectoriesProps {
  style: React.CSSProperties;
}

const LoadingSkeleton = () => (
  <Box
    style={{
      flex: "0 0 auto",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "400px",
      height: "350px",
    }}
  >
    <Skeleton
      variant="rectangular"
      width={100}
      height={100}
      style={{ marginBottom: 10 }}
    />
    <Skeleton variant="text" width={130} />
    <Skeleton variant="text" width={200} />
    <Skeleton variant="text" width={130} style={{ marginBottom: 10 }} />
    <Skeleton variant="text" width={200} height={50} />
  </Box>
);

const HighlightedDirectories = ({ style }: HighlightedDirectoriesProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [directories, setDirectories] = useState<IDirectory[]>([]);

  useEffect(() => {
    setLoading(true);
    getDirectories();
  }, []);

  const getDirectories = async () => {
    try {
      const res = await axios.get(API.GetAllDirectories());
      setDirectories(res.data);
      setLoading(false);
    } catch (err: any) {}
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 0 30px 0",
      }}
    >
      <div style={{ maxWidth: "1200px", textAlign: "center" }}>
        <Typography variant="h5" fontWeight={"bold"}>
          Quickstart guides
        </Typography>
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            maxWidth: "1200px",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <LoadingSkeleton />
          <LoadingSkeleton />
          <LoadingSkeleton />
        </div>
      )}
      <div style={{ ...style, maxWidth: "1200px" }}>
        {directories
          // only highlighted directories
          .filter((directory) => directory.highlighted)
          // order by highlighted order (0 first)
          .sort((a, b) => a.highlightedOrder - b.highlightedOrder)
          .map((directory) => (
            <DirectoryPanel key={directory.id} {...directory} />
          ))}
      </div>
    </div>
  );
};

export default HighlightedDirectories;
